<div class="row justify-content-center">
  <div class="col-12">
      <!-- Header -->
      <div class="header">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <!-- Pretitle -->
              <!-- 
              <h6 class="header-pretitle">
              </h6> 
              -->

              <!-- Title -->
              <h1 class="header-title">
                Dashboard
              </h1>
            </div>

            <div class="col-auto">
              <a *ngIf="version == 'v1'" href="http://sistemav1.cpsadmin.com.br/" target="_blank" class="btn btn-green"><i class="fa fa-download mr-2"></i> Download Provedor.net</a>
              <a *ngIf="version == 'v2'" href="http://201.33.224.195/provedor.net-publish/" target="_blank" class="btn btn-green"><i class="fa fa-download mr-2"></i> Download Provedor.net</a>
            </div>
          </div> <!-- / .row -->
        </div>
      </div>

      <!-- Header -->
      <div class="header mb-0">
          <div class="row align-items-center">
            <div class="col">
              <!-- Pretitle -->
              <h4 class="header-pretitle">
                Clientes
              </h4>
            </div>
          </div> <!-- / .row -->
      </div>

      <!-- Card -->
      <div class="card">
        <div class="list-group">
          <a routerLink="/outros/file-manager" *isInRole="['arq_listar']" class="list-group-item list-group-item-action border-black"><i class="fa fa-folder-open mr-2"></i> Gerenciar Arquivos</a>
          <a routerLink="/redes/ctos/configurar-porta/_" *isInRole="['cto_configurarporta']" class="list-group-item list-group-item-action border-black"><i class="fa fa-plug mr-2"></i> Configurar Porta CTOs</a>
          <a routerLink="/tecnica/ordens" class="list-group-item list-group-item-action border-black"><i class="fa fa-files-o mr-2"></i> Gerenciar Ordens/Fechar OS</a>
          <!-- <a routerLink="/tecnica/os" *isInRole="['ord_osfecharonline']" class="list-group-item list-group-item-action border-black"><i class="fa fa-check-square-o mr-2"></i> Fechar OS [antigo/descontinuado em breve!]</a> -->
          <!-- <a routerLink="outros/contrato-upload" *isInRole="['con_uploadonline']" class="list-group-item list-group-item-action border-black"><i class="fa fa-upload"></i> Upload Contrato</a> -->
          <a routerLink="/tecnica/sinal" *isInRole="['cps_cliente_obtersinal']" class="list-group-item list-group-item-action border-black"><i class="fa fa-signal mr-2"></i> Consultar Sinal</a>
          <a routerLink="/tecnica/zte" *isInRole="['cli_ativaronu']" class="list-group-item list-group-item-action border-black"><i class="fa fa-podcast mr-2"></i> ZTE</a>
        </div>
      </div>

      <!-- Header -->
      <div class="header mb-0" *isInRole="['crt_autorizarpagamentog']">
        <div class="row align-items-center">
          <div class="col">
            <!-- Pretitle -->
            <h4 class="header-pretitle">
              Financeiro
            </h4>
          </div>
        </div> <!-- / .row -->
      </div>

      <!-- Card -->
      <div class="card">
        <div class="list-group">
          <a routerLink="/financeiro/titulo" *isInRole="['crt_autorizarpagamentog']" class="list-group-item list-group-item-action border-black"><i class="fa fa-newspaper-o mr-2"></i> Título</a>
          <a routerLink="clientes/cartao-credito" *isInRole="['crt_autorizarpagamentog']" class="list-group-item list-group-item-action border-black"><i class="fa fa-credit-card mr-2"></i> Cartão de Crédito Autorizar Pagamentos</a>
        </div>
      </div>

      <!-- Header -->
      <div class="header mb-0">
        <div class="row align-items-center">
          <div class="col">
            <!-- Pretitle -->
            <h4 class="header-pretitle">
              Rede
            </h4>
          </div>
        </div> <!-- / .row -->
      </div>

      <!-- Card -->
      <div class="card">
        <div class="list-group">
          <a routerLink="/redes/ctos" *isInRole="['cto_acessar']" class="list-group-item list-group-item-action border-black"><i class="fa fa-map-pin mr-2"></i> Acessar CTOs</a>
          <a routerLink="/redes/ctos/visualizar" *isInRole="['cto_acessar']" class="list-group-item list-group-item-action border-black"><i class="fa fa-eye mr-2"></i> Visualizar CTOs</a>
          <a routerLink="/redes/ctos/configurar-porta/_" *isInRole="['cto_configurarporta']" class="list-group-item list-group-item-action border-black"><i class="fa fa-plug mr-2"></i> Configurar Porta CTOs</a>
          <a routerLink="clientes" *isInRole="['cps_ppp_online']" class="list-group-item list-group-item-action border-black"><i class="fa fa-connectdevelop border-black" style="color: greenyellow;"></i> PPPoE</a>
        </div>
      </div>

      <!-- Header -->
      <div class="header mb-0">
        <div class="row align-items-center">
          <div class="col">
            <!-- Pretitle -->
            <h4 class="header-pretitle">
              Outros
            </h4>
          </div>
        </div> <!-- / .row -->
      </div>

      <!-- Card -->
      <div class="card">
        <div class="list-group">
          <a routerLink="/outros/conversor" *isInRole="['con_acessar']" class="list-group-item list-group-item-action border-black"><i class="fa fa-exchange mr-2"></i> Conversor KML para WKT</a>
        </div>
      </div>
    
  </div>
</div> <!-- / .row -->
