import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { JwPaginationModule } from 'jw-angular-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { config } from './config';
import { LayoutModule } from './layout/layout.module';

import { AuthGuard } from './shared/auth/auth.guard';
import { AuthService } from './shared/auth/auth.service';
import { RoleGuard } from './shared/auth/role.guard';
import { TokenInterceptor } from './shared/auth/token.interceptor';
import { LoadingService } from './shared/services/loading.service';
import { SharedModule } from './shared/shared.module';

export function tokenGetter() {
    return sessionStorage.getItem(config.tokenKey);
}

const MODULES = [
    SharedModule,
    LayoutModule
    //ContentModule
];

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        MODULES,
        JwtModule.forRoot({ config: { tokenGetter: tokenGetter } }),
        NgbModule
        // JwPaginationModule
    ], providers: [
        AuthService,
        LoadingService,
        AuthGuard,
        RoleGuard,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
