import { NgModule, ComponentFactoryResolver, Directive, Input, ElementRef, Output, EventEmitter, OnInit, Component, AfterViewInit, HostListener, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgModel } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { fromEvent as observableFromEvent, Subscription, Observable } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';


import { Utils } from 'src/app/shared/utils/utils';


declare let $;

@Directive({
    selector: '[autoComplete]'
})

export class AutoCompleteDirective implements OnInit, AfterViewInit, OnDestroy {

    private searcher: any;

    private model: any;

    items: any[] = null;

    itemActive: number = -1;

    private modeSearch: string;

    private focused: boolean;

    private dropDown: AutoCompleteList;

    private forceInsert = false; // usado pra definir que foi clicado o botão de insert então não deve-se enviar o model no initInject

    subs: Subscription[] = [];

    $buttonSearch;

    @Input() pageSize: any;

    @Input('autoComplete') serviceUrl: any;

    @Input() displayFields: any;

    @Input() template: any;

    @Input() paramSearch: any;

    @Input() groupField: string;

    @Input() sortByGroupField = true;

    @Input() displayFieldsRender: (model: any) => string;
    @Input() displayFieldsSelect: (label: string, model: any) => string;

    private _disabled = false;
    @Input('disabled') set disabled(value: boolean) {
        this._disabled = value;
        this.checkDisabled();
    }

    @Output() formatSearch = new EventEmitter();

    registerOnChange = (fn: (_: any) => void): void => { this._onChangeCallback = fn; };
    registerOnTouched = (fn: () => void): void => { this._onTouchedCallback = fn; };

    private _onChangeCallback: (value: any) => void = (value) => { };
    private _onTouchedCallback: () => void = () => { };

    constructor(private el: ElementRef,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private viewContainerRef: ViewContainerRef,
        private ngModel: NgModel,
        private resolver: ComponentFactoryResolver) { }

    writeValue = (value: any) => { // do model pro input
        /**
         * Durante a carga inicial o objeto vem null, depois vem sem as propriedades e na terceira vez vem completo
         * a comparação abaixo é para tratar esta questão
         **/
        if (((value !== null) && (value !== undefined)) && (Object.keys(value).length !== 0)) {
            this.updateView(value);
            this.model = value;
        } else {
            this.model = undefined;
            this.updateView('');
        }
    }

    ngAfterViewInit() {
        this.$buttonSearch = $(`
        <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" aria-haspopup="true" aria-expanded="false">
                <span class="sr-only">Toggle Dropdown</span>
            </button>
        </div>`
        ).on('click', () => {
            if (this.dropDown.isVisible) {
                this.dropDown.setVisible(false);
            } else {
                setTimeout(() => {
                    this.el.nativeElement.select();
                    this.el.nativeElement.focus();
                    this.search('click');
                }, 0);
            }
        });
        
        let $el = $(this.el.nativeElement);

        let $list = $el.parent().find('autocomplete-list');//.detach();
        
        $el.wrap('<div class="input-group">');
        let $label = $el.find('label');
        let $elParentButtons;
        if ($label[0]) {
            $elParentButtons = $label;
        } else {
            $elParentButtons = $el;
        }
        $elParentButtons.after(this.$buttonSearch);
        $el.after($list);
        this.checkDisabled();
    }

    ngOnInit() {

        this.ngModel.valueAccessor.registerOnChange = this.registerOnChange;
        this.ngModel.valueAccessor.registerOnTouched = this.registerOnTouched;
        this.ngModel.valueAccessor.writeValue = this.writeValue;


        this.ngModel.valueChanges.subscribe(n => {
            this.model = n;
        });

        /**
         * Observable para atribuir valor indefinido para o model sempre que ocorrer alteração do input
         **/
        observableFromEvent(this.el.nativeElement, 'input')
            .pipe(debounceTime(0))
            .subscribe(() => {
                if (this.ngModel.control.errors && this.ngModel.control.errors['autocomplete']) {
                    delete this.ngModel.control.errors['autocomplete'];
                }
                if (this.model) {
                    this.updateModel(undefined);
                }
            });

        /**
         * Observable para executar serviço de pesquisa sempre que ocorrer alteração do input
         **/
        this.searcher = observableFromEvent(this.el.nativeElement, 'input').pipe(debounceTime(250), switchMap(data => {
            return this.search('change');
        })).subscribe(data => {
            this.searchSetResult(data);
        });

        this.focused = false;

        this.modeSearch = null;

        this.items = null;

        this.validInputs();

        let cr = this.viewContainerRef.createComponent(this.resolver.resolveComponentFactory(AutoCompleteList));
        cr.instance.autocomplete = this;
        cr.instance.template = this.template;
        this.dropDown = cr.instance;
    }

    private checkDisabled() {
        //let $divWrap = $(this.el.nativeElement).parent();
        if (this._disabled) {
            // if ($divWrap) {
            //     $divWrap.removeClass('input-group');//.removeClass('right-addon');
            // }
            if (this.$buttonSearch) {
                this.$buttonSearch.hide();
            }
        } else {
            // if ($divWrap) {
            //     $divWrap.addClass('input-group');//.addClass('right-addon');
            // }
            if (this.$buttonSearch) {
                this.$buttonSearch.show();
            }
        }
    }
    public updateView(model: any) {
        let cleanRender = this.formatLabel(model);
        if (this.displayFieldsSelect) {
            cleanRender = this.displayFieldsSelect(cleanRender, model);
        }

        this.el.nativeElement.value = cleanRender.replace(/<.*?>/g, '');
    }

    public selectItem(item: any, e?: Event) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (item != null) {
            this.updateModel(item);
            this.updateView(item);
        }
        setTimeout(() => {
            this.items = null;
            this.dropDown.setVisible(false);
            this.validModel();
        }, 150);
    }

    public updateModel(model: any) {
        // console.log("veio aqui");
        // console.log(model);
        this.model = model;
        this._onChangeCallback(model);
    }

    private autoSelectItem() {
        if (this.model) {
            return;
        } else if (!this.items) {
            return;
        } else if (!this.el.nativeElement.value) {
            return;
        }
        let valueWithoutAccent = Utils.removeAcentos(this.el.nativeElement.value).toLowerCase();
        let fields = this.displayFields.split(',');
        if (this.items.length === 1) {
            this.selectItem(this.items[0]);
        } else {
            for (let item of this.items) {
                for (let field of fields) {
                    field = field.trim();
                    if (Utils.removeAcentos(item[field]).toLocaleLowerCase() === valueWithoutAccent) {
                        this.selectItem(item);
                    }
                }
            }
        }
    }

    private search(modeSearch) {
        this.modeSearch = modeSearch;
        let searchDto = { 'textoPesquisa': this.el.nativeElement.value, 'registrosPagina': this.pageSize, paramSearch: this.paramSearch };
        if (this.formatSearch) {
            this.formatSearch.emit(searchDto);
        }

        let options = searchDto.textoPesquisa ?
            { params: new HttpParams().set('s', searchDto.textoPesquisa) } : {};

        if (modeSearch === 'click') {
            //searchDto.textoPesquisa = '';
            options = {};
            this.http.get(this.serviceUrl, options).subscribe(r => {
                this.searchSetResult(r);
            });
        } else {
            if ((searchDto.textoPesquisa) && (searchDto.textoPesquisa.trim() !== '')) {
                return this.http.get(this.serviceUrl, options);
            } else {
                return [];
            }
        }
    }

    private searchSetResult(data) {
        this.items = data.data;
        if (!(this.items instanceof Array && this.items.length > 0)) {
            this.dropDown.setVisible(false);
            return;
        }
        this.itemActive = -1;
        if ((this.focused) || (this.modeSearch === 'click')) {
            this.dropDown.setVisible(true);
            $(this.el.nativeElement).find('ul').show();
        }
        if ((!this.focused) && (this.modeSearch === 'change')) {
            this.autoSelectItem();
        }

        if (this.groupField && this.items && this.items[0]) {
            if (this.sortByGroupField) {
                this.items = Utils.orderBy(this.items, this.groupField as any);
            }
            let currentGroup = Utils.resolve(this.groupField, this.items[0]);
            this.items[0].grouped = currentGroup;

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                let group = Utils.resolve(this.groupField, item);
                if (group !== currentGroup) {
                    currentGroup = group;
                    item.grouped = currentGroup;
                }
            }
        }
    }

    formatLabel(model) {
        if (!model) {
            return '';
        }
        if (this.displayFieldsRender) {
            return this.displayFieldsRender(model);
        }
        let fields = this.displayFields.split(',');
        let label = '';
        for (let field of fields) {
            field = field.trim();
            let valueField = Utils.resolve(field, model);
            if (!valueField) {
                continue;
            }
            if (label !== '') {
                label += ' - ';
            }
            label += valueField;
        }
        return label;
    }

    @HostListener('focus', ['$event'])
    protected onFocus() {
        this.focused = true;
    }

    @HostListener('blur', ['$event'])
    protected onBlur() {
        this.focused = false;
        this.autoSelectItem();
        this.validModel();
    }

    protected validModel() {
        let error = false;
        if (this.el.nativeElement.value) {
            if (!this.model) {
                error = true;
            }
        }
        if (error) {
            this.ngModel.control.setErrors({ autocomplete: 'Nenhum registro encontrado com o conteúdo informado' });
        }
    }

    protected validInputs() {
        if (this.serviceUrl === undefined) {
            throw new Error(`serviceUrl: deve conter uma url de serviço válida`);
        }
        if (this.displayFields === undefined) {
            throw new Error(`displayFields: deve conter uma lista com um ou mais campos separados por virgula`);
        }
        if (this.pageSize === undefined) {
            this.pageSize = 20;
        }
    }

    @HostListener('keydown', ['$event'])
    protected onKeydown(e: KeyboardEvent): void {

        if (e.keyCode === 40) {
            if (this.items != null && this.items.length > 0) {
                this.dropDown.setVisible(true);
            } else {
                this.search('click');
            }
        }

        if (!this.items) {
            return;
        }

        // enter
        if (e.keyCode === 13) {
            e.preventDefault();
            if (this.itemActive !== -1) {
                this.selectItem(this.items[this.itemActive]);
            } else { // se der enter e sair do campo
                this.dropDown.setVisible(false);
            }
            return;
        }

        // up
        if (e.keyCode === 38) {
            e.preventDefault();
            if (this.items.length === 0) {
                return;
            }
            if (this.itemActive === -1) {
                this.itemActive = 0;
            } else if (this.itemActive > 0) {
                this.itemActive--;
            }

            let div = (<HTMLDivElement>this.el.nativeElement.nextSibling).getElementsByClassName('dropdown-menu').item(0);
            if (!div) {
                return;
            }

            let a: HTMLAnchorElement = div.getElementsByTagName('a').item(this.itemActive);

            if (((a.offsetTop + a.clientHeight) - div.scrollTop) <= a.clientHeight) {
                a.scrollIntoView({ block: 'nearest' });
            }


            // let ul = (<HTMLElement>this.el.nativeElement.nextSibling).getElementsByTagName('ul').item(0);
            // if (!ul) {
            //     return;
            // }

            // let li: HTMLLIElement = [].slice.call(ul.getElementsByTagName('li')).filter(n => !n.classList.contains('group'))[this.itemActive];

            // if (((li.offsetTop + li.clientHeight) - ul.scrollTop) <= li.clientHeight) {
            //     li.scrollIntoView(true);
            // }

            return;
        }

        // down
        if (e.keyCode === 40) {
            e.preventDefault();
            if ((this.items === null) || (this.items.length === 0)) {
                this.search('click');
                return;
            } else if (this.itemActive < (this.items.length - 1)) {
                this.itemActive++;
            }

            let div = (<HTMLDivElement>this.el.nativeElement.nextSibling).getElementsByClassName('dropdown-menu').item(0);
            if (!div) {
                return;
            }

            let a: HTMLAnchorElement = div.getElementsByTagName('a').item(this.itemActive);

            let count = div.clientHeight / a.clientHeight | 0;

            if (((div.clientHeight / a.clientHeight) - count) < .5) {
                count -= 1;
            }

            if ((a.offsetTop - div.scrollTop) >= (a.clientHeight * count)) {
                a.scrollIntoView({ block: 'nearest' });
            }


            // let ul = (<HTMLElement>this.el.nativeElement.nextSibling).getElementsByTagName('ul').item(0);
            // if (!ul) {
            //     return;
            // }

            // let li: HTMLLIElement = [].slice.call(ul.getElementsByTagName('li')).filter(n => !n.classList.contains('group'))[this.itemActive];

            // let count = ul.clientHeight / li.clientHeight | 0;

            // if (((ul.clientHeight / li.clientHeight) - count) < .5) {
            //     count -= 1;
            // }

            // if ((li.offsetTop - ul.scrollTop) >= (li.clientHeight * count)) {
            //     li.scrollIntoView(false);
            // }

            return;
        }

        // esc or tab
        if (e.keyCode === 27 || e.keyCode === 9) {
            if (this.dropDown) {
                this.dropDown.setVisible(false);
                this.itemActive = -1;
            }
            return;
        }

    }


    ngOnDestroy() {
        this.viewContainerRef.clear();
        this.subs.forEach(n => {
            n.unsubscribe();
        });
    }
}

@Component({
    selector: 'autocomplete-list',
    host: { '(document:click)': 'onClickOut($event)' },
    template: `
        <div *ngIf="visible" class="dropdown-menu mt-1 show" style="max-height: 200px;">
            <ng-template ngFor [ngForOf]="autocomplete.items" let-item>
            <a href [class.active]="isActive(item)" (click)="autocomplete.selectItem(item, $event)" class="dropdown-item">
                <ng-container *ngIf="!template">{{ display(item) }}</ng-container>
                <ng-container *ngTemplateOutlet="template; context: {$implicit:item}"></ng-container>
            </a>
            </ng-template>
        </div>
        <ul *ngIf="false" class="dropdown-menu sa autocomplete-dropdown" style="max-height: 200px">
            <ng-template ngFor [ngForOf]="autocomplete.items" let-item>
            <li class="group" *ngIf="item?.grouped">{{ item.grouped }}</li>
            <li [class.active]="isActive(item)">
                <a href (click)="autocomplete.selectItem(item, $event)">
                    <ng-container *ngIf="!template">{{ display(item) }}</ng-container>
                    <ng-container *ngTemplateOutlet="template; context: {$implicit:item}"></ng-container>
                </a>
            </li>
            </ng-template>
        </ul>
    `,
    styles: [`
        .dropdown-menu {
            width: 100%;
            overflow-x: hidden;
            box-shadow: 5px 5px rgba(102,102,102,.1);
            overflow-y: auto;
        }
    `]
})
export class AutoCompleteList {

    @Input('autocomplete') autocomplete: AutoCompleteDirective;
    @Input('template') template;

    visible: boolean = false;

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) { }

    onClickOut(e) {
        if (!((e.target === this.autocomplete.$buttonSearch[0]) ||
            (e.target === this.autocomplete.$buttonSearch.find('i')[0])) &&
            (!e.target.classList.contains('group'))) {
            this.setVisible(false);
            this.autocomplete.itemActive = -1;
        }
    }

    display(item) {
        return this.autocomplete.formatLabel(item);
    }

    public setVisible(value: boolean) {
        this.visible = value;
        this.cdr.markForCheck();
    }

    public get isVisible() {
        return this.visible;
    }

    isActive(item) {
        let r = false;
        let it = this.autocomplete.items[this.autocomplete.itemActive];
        if (it) {
            r = it === item;
        }
        return r;
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [AutoCompleteDirective, AutoCompleteList],
    exports: [AutoCompleteDirective, AutoCompleteList]
})
export class AutoCompleteModule { }
