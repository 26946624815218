import { environment } from "src/environments/environment";

export const DynamicMenuA: DynamicMenuConfigType[] = [

];

export const DynamicMenuConfig = {
    items: [
        {
            title: 'Home',
            root: true,
            url: '/',
        },
        {
            title: 'Clientes',
            url: '/clientes',
            submenu: [
                {
                    title: 'Principal',
                    url: '/clientes/cliente',
                    roles: ['arq_listar']
                },
                {
                    title: 'Arquivos',
                    url: '/outros/arquivos',
                    roles: ['arq_listar']
                }
            ]
        },
        {
            title: 'Financeiro',
            url: '/financeiro',
            submenu: [
                {
                    title: 'Cartão de Crédito Autorizar Pagamentos',
                    url: '/clientes/cartao-credito',
                    roles: ['crt_autorizarpagamentog']
                },
                {
                    title: 'Listar Boletos',
                    url: '/financeiro/boleto',
                    roles: ['crt_autorizarpagamentog']
                },
                {
                    title: 'PIX',
                    url: '/financeiro/pix',
                    roles: ['pix_acesso']
                }
                // {
                //     title: 'Listar PIX',
                //     url: '/financeiro/listarpix',
                //     roles: ['pix_acesso']
                // },
                // {
                //     title: 'Conciliar PIX',
                //     url: '/financeiro/conciliarpix',
                //     roles: ['pix_conciliacao']
                // }
                // {
                //     title: 'Listar Títulos',
                //     url: 'financeiro/titulo',
                //     roles: ['cto_cadastrar']
                // }
            ]
        },
        {
            title: 'Redes',
            url: '/redes',
            submenu: [
                {
                    title: 'Acessar CTOs',
                    url: '/redes/ctos',
                    roles: ['cto_acessar']
                },
                {
                    title: 'Configurar Porta CTOs',
                    url: '/redes/ctos/configurar-porta/_',
                    roles: ['cto_configurarporta']
                },
                {
                    title: 'Visualizar CTOs',
                    url: '/redes/ctos/visualizar',
                    roles: ['cto_acessar']
                }
            ]
        },
        {
            title: 'Técnica',
            url: '/tecnica',
            submenu: [
                // {
                //     title: 'Acessar Ordens [Instalação/Manutenção/Venda]',
                //     url: '/tecnica/os',
                //     roles: ['ord_osfecharonline']
                // },
                // {
                //     title: 'Fechar OS (antigo)',
                //     url: '/tecnica/os',
                //     roles: ['ord_osfecharonline']
                // },
                {
                    title: 'Fechar OS (novo!)',
                    url: '/tecnica/ordens'
                },
                {
                    title: 'Gerenciar Ordens (novo!)',
                    url: '/tecnica/ordens'
                },
                {
                    title: 'Salvar Localização Cliente',
                    url: '/tecnica/localizacao-cliente',
                    roles: ['ord_osfecharonline']
                },
                {
                    title: 'Obter Sinal Cliente [Em Teste]',
                    url: '/tecnica/sinal',
                    roles: ['cps_cliente_obtersinal']
                },
                {
                    title: 'ZTE',
                    url: '/tecnica/zte',
                    roles: ['cli_ativaronu']
                },
                {
                    title: 'Radius Corrigir RadAccts',
                    url: '/tecnica/radius-corrigir',
                    roles: ['tec_corrigir_radius']
                }
                // ,
                // {
                //     title: 'ZTE Buscar ONU [Em Teste]',
                //     url: '/tecnica/zte/zte-buscar-onu',
                //     roles: ['cps_cliente_obtersinal']
                // }
            ]
        },
        // {
        //     title: 'Estoque',
        //     url: '/estoque',
        //     submenu: [
        //         {
        //             title: 'Informações',
        //             url: '/estoque/index',
        //             roles: ['est_acessar']
        //         }
        //     ]
        // },
        {
            title: 'Relatório',
            url: '/relatorio',
            submenu: [
                {
                    title: 'Listar Relatórios',
                    url: '/relatorio/index',
                    roles: ['cps_rel_acessar']
                }
            ]
        },
        {
            title: 'Outros',
            url: '/outros',
            submenu: [
                {
                    title: 'Arquivos',
                    url: '/outros/file-manager',
                    roles: ['arq_listar']
                },
                // {
                //     title: 'Upload Contrato',
                //     url: '/outros/contrato-upload',
                //     roles: ['con_uploadonline']
                // },
                {
                    title: 'Conversor',
                    url: '/outros/conversor',
                    roles: ['con_acessar']
                }
            ]
        }
    ]
};

export interface DynamicMenuConfigType {
    title: string;
    url: string;
    root?: boolean;
    roles: string[];
    submenu: DynamicMenuConfigType[]
}

export module config {
    export var new_api = true;                        // Utiliza a nova API CpsAdminAPI;
    // export var debug = false;
    export var ROOT_API = '';
    export var version = '';                          // Versão que vai ser utilizada p/ definir o BD;

    // export var ROOT_API = '/webapi/api/v1/' //'http://201.33.224.195:5050/api/';

    if (!environment.production) {
        if(new_api)
            ROOT_API = 'http://localhost:5001/';
        else
            ROOT_API = 'http://localhost:60808/api/v2/';
        // ROOT_API = 'http://localhost:60808/api/v2/';
        // ROOT_API = 'http://www.cpsadmin.com.br/webapi/api/'
    }
    else
    {
        if(new_api)
            ROOT_API = '/api/';                 // Ver qual é a sintaxe que vamos utilizar no servidor
        else
            ROOT_API = '/webapi/api/v1/';       //'http://201.33.224.195:5050/api/'
    }

    //console.log(ROOT_API);

    export let TOKEN: string = null;

    export const GOOGLE_API_KEY = 'AIzaSyCMkLFentJ2bSlPOEhz2bHmPlM-48UNJbY';

    export let tokenKey: string = 'app_token';
    export let rolesKey: string = 'app_roles';
    export let userKey: string = 'app_user';

    //export const AUTOCOMPLETE_MAX_HEIGHT = 204;

    // export const treeView = {
    //     iconFolder: 'fa fa-folder icon-state-warning',
    //     iconFile: 'fa fa-file icon-state-success'
    // };

    //export var environment: any = {};
}

export module crud {
    export const Messages = {
        REGISTRO_EXCLUIDO_SUCESSO: 'Registro excluído com sucesso',
        REGISTRO_SALVO_SUCESSO: 'Registro salvo com sucesso',
        REGISTRO_NAO_ENCONTRADO: 'Registro não encontrado'
    };

    export enum EOperation {
        INSERT,
        UPDATE,
        DELETE,
        NONE
    }
}
